<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import PageHeader from "@/components/page-header";
// import router from "@/router"
// import moment from 'moment'
import DatePicker from "vue2-datepicker";
import {formatDatepicker, formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Order Report",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  setup(){

    const { $get } = useHttp()

   const LOV = ref({
      ports: [],
      // schedules: [],
      vendor:[],
      dataExcel:[]
    })

   const form = ref({
      vendor:'',
      ordDateFrom:'',
      ordDateThru:'',
      departuredate: '',
      depature_time_from:'',
      depature_time_to:'',
    //   departuretime:'',
      origin : '',
      destination : '',
      order:[]
    })

    /*const date1 = new Date()
    const date2 = new Date()
    const paramdate = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])
    const paramdeparture = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])*/
    const paramdate = ref(['',''])
    const paramdeparture = ref(['',''])

    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

    const getVendor = async () => { 
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendor = data
      }

    /*const setDataParams = async () => {
      if(props.origin){
        form.value.vendor = LOV.value.vendor.filter(data => data["vnd_name"] === props.vendor)[0]
        form.value.ordDate = props.ord_date
        form.value.departuredate = props.departure_date//new Date(props.departure_date.replace(/(..)-(..)-(....)/, '$3-$2-$1'))
        form.value.origin = LOV.value.ports.filter(data => data["prt_initial"] === props.origin)[0]
        form.value.destination = LOV.value.ports.filter(data => data["prt_initial"] === props.destination)[0]
      }
    }*/

     const getOrderlist = async() =>{  
        let scheduleParams = {
          vendor: form.value.vendor.id,
          ordDateFrom : paramdate.value[0],
          ordDateThru : paramdate.value[1],
          departuredateFrom : paramdeparture.value[0],
          departuredateThru : paramdeparture.value[1],
          depature_time_from : form.value.depature_time_from,
          depature_time_to : form.value.depature_time_to,
          origin: form.value.origin.prt_initial,
          destination : form.value.destination.prt_initial
        }
        const {data} = await $get({ url: 'master/report_order', params: scheduleParams})
        form.value.order = data
        LOV.value.dataExcel = data
    }

    const searchFlight = () =>{ 
      getOrderlist()
      // router.replace({ name: 'apps-report-list' })
    }

    onMounted(() => {
     getPorts()
     getVendor()
    })

    return {
      LOV,
      form,
      getPorts,
      getVendor,
      getOrderlist,
      searchFlight,
      formatNumber,
      formatDatepicker,
      paramdate,
      paramdeparture
    }
  },
  /*
  props: {
      vendor: String,
      departure_date: String,
      origin : String, 
      destination : String,
      ordDate : String
  },*/

 data() {
    return {
      title: "Order Report",
      items: [],
      select_label: "Select"
    };
  },
  methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    },
    printOrder() {
      window.print()
    },
    /*exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.LOV.dataExcel

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }*/
  }
};
</script>

<style lang="scss">
  .showonlyprint {
    display: none;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
      }
      .showonlyprint {
        display: block;
      }
      .printwrap, .printwrap *  {
        visibility: visible;
      }
      .printwrap {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        padding: 27px 32px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div class="col-lg-12">
             <form class="form-horizontal" role="form">
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order Date"
                    label-for="order_date"
                  >
                    <date-picker  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                  </b-form-group> -->
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Departure Date"
                    label-for="depature_date"
                  >
                 <date-picker v-model="paramdeparture" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Departure Time"
                    label-for="depature_time_from"
                  >
                    <date-picker
                      id="depature_time_from"
                      v-model="form.depature_time_from"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      placeholder="hh:mm"
                      hourCycle= "h23"
                      minutes-step="15"
                      class="col-md-2 float-left mr-1"
                    ></date-picker>
                    <date-picker
                      id="depature_time_to"
                      v-model="form.depature_time_to"
                      format="HH:mm"
                      value-type="format"
                      type="time"
                      placeholder="hh:mm"
                      hourCycle= "h23"
                      minutes-step="15"
                      class="col-md-2"
                    ></date-picker>
                   <!-- <b-form-input v-model="form.remaks" id="depature_time_from" type="time" minutes-step="15" class="col-sm-2 float-left mr-1"></b-form-input>
                   <b-form-input v-model="form.remaks" id="depature_time_to" type="time" minutes-step="15" class="col-sm-2"></b-form-input> -->
                  </b-form-group>
                 <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Airline"
                    label-for="vendor_id"
                    >
                    <multiselect v-model="form.vendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label" ></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Port Origin"
                    label-for="port_origin"
                  >
                    <multiselect id="mv_origin" name="mv_origin" :select-label="select_label" :custom-label="customLabel" v-model="form.origin" :options="LOV.ports"></multiselect>
                  </b-form-group>
                  <!-- <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Port Destination"
                    label-for="dest_origin"
                  >
                    <multiselect id="mv_destination" name="mv_destination" :select-label="select_label" :custom-label="customLabel" v-model="form.destination" :options="LOV.ports"></multiselect>
                  </b-form-group> -->
                  </form>

                <div class="col-12 text-center">
                  <div class="button-items">
                    <b-button variant="primary" @click.prevent="searchFlight">
                      Search
                    </b-button>
                    <!-- <b-button variant="primary" @click.prevent="exportToExcel">
                    <i class="mdi mdi-file-download-outline align-middle"></i>
                      Export Excel
                    </b-button> -->
                  </div>
                </div>

              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 text-right">
                <b-button variant="primary" @click.prevent="printOrder">
                  <i class="ri-printer-line"></i>
                  Print
                </b-button>
              </div>
            </div>
            <div class="row printwrap mt-">
              <div class="col-12 text-center showonlyprint mb-2">
                Order Report
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table font-size-13 table-bordered">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Order No.</th>
                        <th>SMU No.</th>
                        <th>Collies</th>
                        <th>Weight</th>
                        <th>Airline</th>
                        <th>Flight</th>
                        <th>Departure</th>
                        <th>Route</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data,index) in form.order" :key="index">
                        <th scope="row">{{index+1}}</th>
                        <td>{{data.ord_no}}</td>
                        <td>{{data.ord_manifestno}}</td>
                        <td class="text-right">{{formatNumber(data.ord_collies)}}</td>
                        <td class="text-right">{{formatNumber(data.ord_weight)}}</td>
                        <td class="text-center">{{data.vnd_name}}</td>
                        <td class="text-center">{{data.sch_flight_no}}</td>
                        <td class="text-center">{{data.sch_departure_time}}</td>
                        <td class="text-center">{{data.oriprt_initial}}-{{data.desprt_initial}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>